import * as React from "react"
import Layout from "../components/layout"
import styled from 'styled-components';
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS, MARKS } from "@contentful/rich-text-types"

import { graphql} from "gatsby";

export const data = graphql`
query agbQuery {
  agb: allContentfulAgb {
    nodes {
       agbDescription {
        raw
      }
    }
  }
}
`

const AGB = styled.div`
  width: 100%;
  padding: 8rem 20px;
  position: relative;
  
`
const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-center">{children}</p>

const options = {
    renderMark: {
        [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
        [BLOCKS.EMBEDDED_ASSET]: node => {
            return (
                <>
                    <h2>Embedded Asset</h2>
                    <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
                </>
            )
        },
    },
}

const AGBPage = ({data}) => (
    <Layout>
        <>

            <AGB className="impressum">
                <div className="container">
                    {
                        data.agb.nodes.map(node => (

                            <div className="row">
                                <div className="col-md-12">
                                    <h1>Datenschutz</h1>
                                    <div className="agb-text">
                                        {renderRichText(node.agbDescription, options)}
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>

            </AGB>
        </>
    </Layout>
)

export default AGBPage;